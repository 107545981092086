
import Success from '../components/order-success/Success';


export const OrderSuccess = () => {
  return (
    <div>
      <Success></Success>
    </div>
  )
};

export default OrderSuccess;